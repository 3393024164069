"use client";
import {
  Container,
  Divider,
  HStack,
  Image,
  Link,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
// export const metadata = {
//   title: "BeautiBucks",
//   description: "BeautiBucks",
// };
export default function AuthLayout({ children }) {
  const [isBottom, setIsBottom] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      setIsBottom(scrollPosition + windowHeight >= documentHeight - 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Stack minH="100vh">
      <Container as="main" maxW="container.xl" flexGrow={1}>
        {children}
      </Container>
      <Divider />
      <Container maxW="container.xl" bgColor="white">
        <HStack as="footer" justify="end" py={10}>
          <Link href="/home">
            <HStack spacing={2}>
              {isBottom && (
                <Image
                  src="/icons/icon_home.png"
                  h="40px"
                  alt="Beauti buck logo"
                />
              )}
              {!isBottom && (
                <Image
                  src="/icons/icon_home.png"
                  h="40px"
                  alt="Beauti buck logo"
                  style={{
                    position: "fixed",
                    bottom: 10,
                    right: 10,
                    zIndex: 100,
                  }}
                />
              )}
              <Image
                src="/beauti_bucks_logo.jpeg"
                h="60px"
                alt="Beauti buck logo"
              />
            </HStack>
          </Link>
        </HStack>
      </Container>
    </Stack>
  );
}
